import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../../../components/layout'
import PDFsNav from '../../../components/pdfs-nav'
import ResourcesNav from '../../../components/resources-nav'
import LookingForMore from '../../../components/looking-for-more'

export default class PDFs extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Teach with the ${siteTitle} PDFs in your classroom for free! Number patterns, Fun Pages, Workshops, Posters, and more.`
    return (
      <Layout pageTitle="PDFs" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <h1>PDFs</h1>
        <hr />
        <PDFsNav path={this.props.path} />

        <LookingForMore />
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
